.list-group-item.clickable:hover {
  background-color: #eee;
}

.clickable:hover {
  cursor: pointer;
}

pre {
  background-color: #eee;
  padding: 10px 15px;
  border-radius: 5px;
  overflow: scroll;
}

.fs-7 {
  font-size: 9px !important;
}

.code-mirror-bg {
  background-color: #282c34;
  color: white;
}

.br-bl-0 {
  border-bottom-left-radius: 0;
}

.br-br-0 {
  border-bottom-right-radius: 0;
}

.br-bl-1 {
  border-bottom-left-radius: 3px;
}

.br-br-1 {
  border-bottom-right-radius: 3px;
}

.br-0 {
  border-radius: 0;
}

$splyt-blue: rgb(105, 188, 216);
$nav-pills-link-active-bg: $splyt-blue;
$nav-link-color: black;
$nav-link-hover-color: $splyt-blue;
$enable-transitions: false;
$success: #5cb85c;
$warning: #f0ad4e;
$primary: #337ab7;
$border-radius: 8px;
$list-group-bg: white;

.bg-splyt-blue {
  background-color: $splyt-blue;
}

.cm-editor {
  padding: 10px;
  font-size: 13px;
}

@import 'bootstrap/scss/bootstrap';

body {
  font-family: Raleway, var(--bs-font-sans-serif);
}

@include media-breakpoint-up(sm) {

  .my-input {
    width: 160px !important;
  }
}

.field-name {
  color: black;
}

.field-description {
  font-size: 12px;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}

.btn-outline-secondary, select, input {
  box-shadow: none !important;
  outline: none;


  &:focus, &:active {
    //border: unset;
  }
}


h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
